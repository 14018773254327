// Customize Bootstrap variables:
// color variables:
    $white: #fff;
    $grey: rgba(162, 162, 162, 0.81);
    $purple: #16092e;
    $dark-purple: #110624;
    $blue: #0C5DCC;
    $cyan: #0dcaf0;
    $yellow: #faec70;
// theme variables: 
    $primary: $blue;
    $secondary: $grey;
    $info: $cyan;
    $warning: $yellow;
    $dark: $dark-purple;

// modal variables:
    $modal-content-color: $white;
    $modal-content-bg: $dark;
    $btn-close-color: $white !important;

// Import Bootstrap to set changes:
@import "~bootstrap/scss/bootstrap";