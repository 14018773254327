// Import custom bootstrap styles
@import "custom";

// Import fonts:
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300&display=swap');

// Overall Styles
.App {
    background-color: $purple;
    color: $white;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    font-family: Montserrat Alternates, sans-serif;
    overflow-x: hidden;
}

*, *:before, *:after {
    box-sizing: inherit;
}

// About Page
.About {
    font-size: 62.5%; /*equals 10px*/
    height: 100vh;
    width: 100vw;
    background: linear-gradient(#16092e, #7E87DE);
    overflow: hidden;
    position: relative;
}
  
#far-mountains {
    z-index: 10;
    overflow: hidden;
}

#far-mountain-1 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10em 13em 50em;
    border-color: transparent transparent #16082e transparent;
    position: absolute;
    bottom: 0;
    right: -6em;
    filter: blur(0.07em);
}

#far-mountain-2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 35em 12em 25em;
    border-color: transparent transparent #16082e transparent;
    position: absolute;
    filter: blur(0.07em);
    bottom: 0;
    left: -15em;
}

#far-mountain-3 {
    border-bottom: 8em solid #16082e;
        border-left: 8em solid transparent;
        border-right: 8em solid transparent;
        height: 0;
        width: 50em;
    filter: blur(0.07em);
    position: absolute;
    bottom: 0;
    left: 20em;
}

#near-mountains {
    overflow: hidden;
}

#near-mountain-1 {
    border-style: solid;
    border-width: 0 16em 10em 19em;
    border-color: transparent transparent #110624 transparent;
    position: absolute;
    bottom: 0;
    right: -5em;
}

#near-mountain-2 {
    border-bottom: 6em solid #110624;
    border-left: 2.5em solid transparent;
    border-right: 4em solid transparent;
    width: 34em;
    position: absolute;
    bottom: 0;
    left: 22em;
}

#near-mountain-3 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20em 9em 15em;
    border-color: transparent transparent #110624 transparent;
    filter: blur(0.02em);
    position: absolute;
    bottom: 0;
    left: -4em;
}

#near-mountain-4 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15em 9em 12em;
    border-color: transparent transparent #110624 transparent;
    filter: blur(0.03em);
    position: absolute;
    bottom: 0;
    right: 21em;
}

#moon {
    height: 20em;
    width: 19.6em;
    border-radius: 50%;
    background-color: #B2B2B2;
    box-shadow: 0.5em 0 1em 0.5em rgba(229,229,229,0.3),
                0 0 0.8em 0.4em rgba(229,229,229,0.3),
                0.2em 0 0.5em 0.3em rgba(229,229,229,0.3),
                0em 0 0.3em 0.2em rgba(229,229,229,0.3);
    position: absolute;
    top: 20%;
    left: 5%;
    z-index: 99;
}

#moon::after {
    background-color: rgba(150, 150, 150, 1);
    display: block;
    content: "";
    position: absolute;
    bottom: 5.5em;
    right: 3.3em;
    height: 2.6em;
    width: 2.6em;
    border-radius: 50%;
    box-shadow:
        0.3em 0.1em 0.1em 0 rgba(100, 100, 100, 1),
        -3.2em 2em 0.1em 0 rgba(150, 150, 150, 1),
        -3em 2.1em 0 0 rgba(100, 100, 100, 1),
        -0.1em 3em 0.1em -0.5em rgba(150, 150, 150, 1),
        0.2em 3.1em 0.1em -0.5em rgba(100, 100, 100, 1);
}

header {
  z-index: 100;
  position: absolute;
  bottom: 12em;
  right: 30em;
  animation: fadeIn ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}

.star-1 {
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background-color: #FFFDFD;
    position: absolute;
    top: 15%;
    right: 35%;
    box-shadow: -20em 18em 0.1em 0.1em var(--yellow),
                -13em 5em 0.1em 0 var(--white),
                -27em -3em 0.1em 0.001em #0F00BF,
                24em 23em 0.1em 0.001em var(--white),
                11em 1em 0.1em 0.011em var(--blue),
                -5em 12em 0.1em 0.01em var(--blue),
                16em 13em 0.1em 0.01em #0F00BF;
    animation: flicker 5s ease;
    animation-iteration-count: infinite;
}

.star-2 {
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background-color: var(--white);
    position: absolute;
    top: 10%;
    left: 25%;
    box-shadow: 3em 5em 0.1em 0.1em #0F00BF,
                -13em 16em 0.1em 0.05em var(--blue),
                35em 23em 0.05em 0.01em var(--white),
                15em 28em 0.1em 0.1em var(--white),
                22em 26em 0.1em 0.1em var(--blue);
    animation: flicker 7s ease;
    animation-iteration-count: infinite;
}

.star-3 {
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background-color: #FFFDFD;
    position: absolute;
    right: 8em;
    top: 13em;
    box-shadow: -20em 6em 0.1em 0.1em var(--yellow),
                -80em 28em 0.1em 0.1em var(--white),
                6em -11.5em 0.1em 0.1em var(--yellow),
                -18em 16em 0.1em 0.05em var(--yellow);
    animation: flicker 3s ease;
    animation-iteration-count: infinite;
}

.star-4 {
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background-color: #faec70;
    position: absolute;
    top: 3em;
    left: 3em;
    box-shadow: 90em 36em 0.1em 0.1em var(--yellow),
                20em 34em 0.1em 0.1em var(--blue),
                0em 38em 0.1em 0.1em var(--yellow),
                40em 20em 0.1em 0.1em var(--white);
    animation: flicker 4s ease;
    animation-iteration-count: infinite;
}

@keyframes flicker {
    0% {opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {opacity: 0.0;}
}

// Work Page
.Work {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    scroll-behavior: smooth;
}
.project-tile {
    background-color: $dark-purple;
}

.project-tile:hover, .project-tile:focus {
    border: solid 1px $white;
}

button.close {
    border: 0 none !important;
    color: $white;
    opacity: 1;
}

.col-sm-3, .col-sm-4 {
    text-align: center;
    color: $black;
    background-color: $cyan;
    border-radius: 8px;
    margin: 10px;
}

.technologies-label {
    color: $grey;
}

.demo-account-info {
    color: $blue;
    font-size: 0.9em;
}

// Contact Page
.Contact {
    height: 100vh;
    width: 100vw;
}

#contact-title {
    color: $white;
}

#email, #phone {
    color: $cyan;
}

footer {
    border-top: 4px solid $grey;
    padding-top: 3em;
    margin-top: 10em;
}

// Media Queries
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {
    #moon {
        font-size: 0.5em;
    }

    header {
        left: 20%;
        bottom: 25%;
    }

    h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 1.5em;
    }

    #work-title {
        font-size: 2em;
    }

    #contact-title {
        font-size: 2em;
        padding-top: 0 !important;
        margin-top: 0 !important;
    }

    #email {
        text-align: center;
        padding-left: 1em;
    }

    #phone {
        text-align: center;
        padding-left: 3em;
    }

    footer {
        margin-top: 5em;
    }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
    #near-mountains, #far-mountains {
        font-size: 0.75em;
    }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
    #moon {
       font-size: 1em;
    }

    h1 {
        font-size: 6em;
    }

    h2 {
        font-size: 3em;
    }

    #near-mountains, #far-mountains {
        font-size: 1.5em;
    }

    #contact-title {
        padding-top: 5em !important;
    }

    #phone {
        padding-left: 1em;
        text-align: left;
    }

    footer {
        margin-top: 25em;
    }
}

// Large devices (laptops, desktops, 992px and up)
@include media-breakpoint-up(lg) {
    footer {
        margin-top: 10em;
    }

    header {
        left: 35%;
    }

    h1 {
        font-size: 5em;
    }

    h2 {
        font-size: 2.5em;
    }
}
